<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Travail pédagogique pour le DUT MMI</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2019</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Design, animation, vidéo
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Tokyo Ghoul | Us all est un projet vidéo réalisé en motion design
						dans le cadre d'un exercice d'apprentissage au DUT Métiers du
						Multimédia et de l'Internet. Pour ce projet il nous a été demandé de
						choisir une série, un film ou un animé ainsi qu'une musique
						appropriée et d'en faire un montage vidéo en motion design.
						<br /><br />
						J'ai choisi pour ma part les trois saisons de l'animé « Tokyo Ghoul
						» et la musique « Us All » du groupe Silent Season. J'ai ensuite
						défini ce que je souhaitais transmettre et faire ressortir à travers
						la vidéo. <br /><br />
						Ainsi, j'ai réalisé un storyboard avant de commencer la conception
						de la vidéo en elle-même pour fixer les clips que j'allais utiliser
						aux différents moments de la musique et créer du rythme. Pour finir
						j'ai réalisé la conception de la vidéo à l'aide du logiciel Adobe
						After Effect que nous avions seulement commencé à utiliser.
					</p>

					<!--Vidéo 1-->

					<iframe
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12 videoYoutube"
						height="700"
						src="https://www.youtube.com/embed/_l5evG0ER5g"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
						style="border: none"
					></iframe>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Vidéo du motion design final
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Tokyo Ghoul",
			meta: [
				{
					name: "description",
					content:
						'Découvrez le projet motion design "Tokyo Ghoul" réalisé par Antonin Winterstein !',
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "TOKYO GHOUL | US ALL - MOTION DESIGN",
				urlImage: require("../assets/projets/fond_tokyo_ghoul.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
